import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'

import NewsletterBasic from 'components/forms/newsletter/NewsletterBasic'
import NewsletterForm from 'containers/forms/NewsletterForm'
import FormWrapper from 'components/forms/components/form-wrapper'

class Newsletter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showForm: true,
            showThankyou: false,
            showVerified: false,
            showError: false,
            errorCode: 0,
            errorMessage: '',
        }
    }

    componentDidMount() {
        console.log('s', this.props.location.search)
        const { location } = this.props
        console.log('location')
        const { search } = location
        const values = queryString.parse(search.toLowerCase())
        console.log('values', values)

        if (search === '?thankyou') {
            // console.log('THANK YOU')
            this.setState({ showForm: false, showThankyou: true })
        } else if (search === '?verified') {
            // console.log('VERIFIED')
            this.setState({ showForm: false, showVerified: true })
        } else if (values.errorcode) {
            // console.log('HAS ERROR:', values.errorcode)
            this.setState({ showError: true, errorCode: values.errorcode })
            // TODO map the error down to the form and show it
            let errorMessage = ''
            if (values.errorcode === '8') {
                errorMessage = 'Email address already registered'
            } else if (values.errorcode === '4') {
                errorMessage = 'Please enter a valid email address'
            } else if (values.errorcode === '3') {
                errorMessage = 'You have to agree to the terms'
            } else {
                errorMessage = 'Something went wrong. Please try again later or contact customer support'
            }
            this.setState({
                errorMessage,
            })
        }

        // console.log('Search', search)
        // console.log('thankyou', values.thankyou)
        // console.log('verified', values.verified)
        // console.log('thankyou', values.thankyou)
        // const showThanks = values.thankyou
    }

    render() {
        const {
            showForm,
            showThankyou,
            showVerified,
            showError,
            errorCode,
            errorMessage,
        } = this.state
        return (
            <Fragment>
                <FormWrapper title="Newsletter">
                    {showForm ? (
                        <NewsletterForm errorMessage={errorMessage} />
                    ) : (
                        <Fragment>
                            {showVerified && (
                                <div>
                                    <h1>Thank you</h1>
                                    <p>Your email has been verified</p>
                                </div>
                            )}
                            {showThankyou && (
                                <div>
                                    <h1>Thank you</h1>
                                    <p>A verification email will be sent to you shortly</p>
                                </div>
                            )}
                        </Fragment>
                    )}
                </FormWrapper>
                {/* <h1>WORKS</h1>
                <NewsletterBasic />
                <div>
                    <h3>STUFF</h3>

                    {showError && (
                        <div>
                            SHOW ERROR: error code: {errorCode}
                            {errorCode === '8' && <p>SHOW ALREADY REGISTERED</p>}
                            {errorCode === '4' && <p>SHOW PLEASE ENTER VALID EMAIL</p>}
                            {errorCode === '3' && <p>You have to agree to the terms</p>}
                            <p>ELSE SHOW COMMON ERROR</p>
                        </div>
                    )}
                </div> */}
            </Fragment>
        )
    }
}

Newsletter.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string,
    }).isRequired,
}

export default Newsletter
