import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'semantic-ui-react'
import { SquareButton } from '../../button'
import '../Form.scss'
import './Newsletter.scss'

class Signup extends Component {
    render() {
        const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            errorMessage,
            // setErrors,
            // handleSubmit,
            // setStatus,
        } = this.props

        // setErrors({ email: 'error' })

        return (
            <form
                // onSubmit={handleSubmit}
                action="https://cl.exct.net/subscribe.aspx"
                name="subscribeForm"
                method="post"
                className="newsletter ui form"
            >
                {errorMessage && <h1>{errorMessage}</h1>}
                <input
                    type="hidden"
                    name="thx"
                    value="https://localhost:3000/newsletter?thankyou"
                />
                <input type="hidden" name="err" value="https://localhost:3000/newsletter" />
                <input type="hidden" name="SubAction" value="sub" />
                <input type="hidden" name="MID" value="7231600" />
                <input
                    className="hide"
                    type="text"
                    name="Email Address"
                    value={values.email}
                    placeholder=""
                />
                <input
                    className="form-check-input hide"
                    type="checkbox"
                    id="confirmation"
                    name="lid"
                    value="19405"
                    checked={values.terms}
                />
                <div className="field">
                    <label htmlFor="email" style={{ display: 'block' }}>
                        Email
                        <div className="ui transparent input">
                            <input
                                id="email"
                                type="text"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={
                                    errors.email && touched.email
                                        ? 'text-input error'
                                        : 'text-input'
                                }
                            />
                        </div>
                    </label>
                    {errors.email && touched.email ? (
                        <div className="input-feedback">{errors.email}</div>
                    ) : (
                        <div className="input-feedback-empty" />
                    )}
                </div>

                <div className="field">
                    <Checkbox
                        id="terms"
                        label={(
                            <label htmlFor="terms">
                                I agree to allow CCP Games to send me product news and promotional
                                communications.
                            </label>
                        )}
                        onChange={handleChange}
                    />
                    {errors.terms && touched.terms ? (
                        <div className="input-feedback">{errors.terms}</div>
                    ) : (
                        <div className="input-feedback-empty" />
                    )}
                </div>
                <div className="field">
                    <SquareButton disabled={isSubmitting}>Sign me up</SquareButton>
                </div>
            </form>
        )
    }
}

Signup.propTypes = {
    values: PropTypes.shape({}),
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    handleSubmit: PropTypes.func,
    setFieldValue: PropTypes.func,
    isSubmitting: PropTypes.bool,
}
Signup.defaultProps = {
    values: null,
    errors: null,
    touched: false,
    handleChange: null,
    handleBlur: null,
    handleSubmit: null,
    setFieldValue: null,
    isSubmitting: false,
}

export default Signup
