import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import style from './Button.module.scss'

const LoginButton = ({ children, to, onClick, className }) => {
    if (to == null) {
        return (
            <button type="button" className={classnames(style.loginButton, className)} onClick={onClick}>
                {children}
            </button>
        )
    }
    return (
        <Link to={to} className={style.loginButton}>
            {children}
        </Link>
    )
}

LoginButton.propTypes = {
    children: PropTypes.node.isRequired,
    to: PropTypes.string, // eslint-disable-line
    onClick: PropTypes.func,
    className: PropTypes.string,
}

LoginButton.defaultProps = {
    onClick: null,
    className: null,
}

export default LoginButton
