import fontawesome from '@fortawesome/fontawesome'

// Brands

// Social
import facebookF from '@fortawesome/fontawesome-free-brands/faFacebookF'
import twitter from '@fortawesome/fontawesome-free-brands/faTwitter'
import youtube from '@fortawesome/fontawesome-free-brands/faYoutube'
import youtubeSquare from '@fortawesome/fontawesome-free-brands/faYoutubeSquare'
import google from '@fortawesome/fontawesome-free-brands/faGooglePlusG'
import instagram from '@fortawesome/fontawesome-free-brands/faInstagram'
import vk from '@fortawesome/fontawesome-free-brands/faVk'
import discord from '@fortawesome/fontawesome-free-brands/faDiscord'

// Platforms
import windows from '@fortawesome/fontawesome-free-brands/faWindows'

// Light
// import download from '@fortawesome/fontawesome-pro-light/faDownload'
import timesCirle from '@fortawesome/fontawesome-pro-light/faTimesCircle'
import angleRight from '@fortawesome/fontawesome-pro-light/faAngleRight'
import times from '@fortawesome/fontawesome-pro-light/faTimes'

// Solid
import square from '@fortawesome/fontawesome-pro-solid/faSquare'
import download from '@fortawesome/fontawesome-pro-solid/faDownload'

const light = [download, timesCirle, angleRight, times]
const solid = [square, download]
const brands = [facebookF, twitter, youtube, youtubeSquare, google, instagram, vk, discord, windows]

fontawesome.library.add(...solid, ...light, ...brands)
