import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import YouTube from 'react-youtube'
import style from './Video.module.scss'
import Diamond from '../common/diamondShape/Diamond'
import videoPoster from '../../static/video-bg.jpg'
import Icon from '../fontAwesome/FontAwesome'
import { LoginButton } from '../button'

class Video extends Component {
    state = {
        isPlaying: false,
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleEsc, false)
    }
    
    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleEsc, false)
    }

    playVideo = () => {
        this.setState({ isPlaying: true })
    }

    stopVideo = () => {
        this.setState({ isPlaying: false })
    }

    handleEsc = (e) => {
        // Allow user to close video with escape key
        const { isPlaying } = this.state
        if (isPlaying && e.keyCode === 27) this.setState({ isPlaying: false })
    }

    renderVideo() {
        const { isPlaying } = this.state
        const { id } = this.props

        const opts = {
            height: '100%',
            width: '100%',
            playerVars: {
                autoplay: 1,
                showinfo: 0,
                rel: 0,
            },
        }

        if (isPlaying) {
            return (
                <CSSTransition
                    timeout={1000}
                    classNames={{
                        enter: style.fade_enter,
                        enterActive: style.fade_enter_active,
                        enterDone: style.fade_enter_done,
                        exit: style.fade_exit,
                        exitActive: style.fade_exit_active,
                        exitDone: style.fade_exit_done,
                    }}
                >
                    <div className={style.inner}>
                        <YouTube
                            videoId={id}
                            opts={opts}
                            className={style.player}
                            onEnd={this.stopVideo}
                        />
                        <LoginButton className={style.close} onClick={this.stopVideo}><Icon light name="times" /></LoginButton>
                    </div>
                </CSSTransition>
            )
        }

        return false
    }

    render() {
        const { caption, poster, className } = this.props
        return (
            <div className={className}>
                <div
                    onClick={this.playVideo}
                    role="presentation"
                    className={style.poster}
                >
                    <img src={poster} alt="" />
                    <Diamond name="playButton" className={style.playButton} />
                </div>
                <figcaption className={style.caption}>{caption}</figcaption>
                <TransitionGroup>{this.renderVideo()}</TransitionGroup>
            </div>
        )
    }
}

Video.propTypes = {
    id: PropTypes.string.isRequired,
    caption: PropTypes.string,
    poster: PropTypes.string,
    className: PropTypes.string,
}

Video.defaultProps = {
    caption: null,
    poster: videoPoster,
    className: null,
}

export default Video
