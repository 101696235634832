import React from 'react'
import PropTypes from 'prop-types'
import style from './SquareButton.module.scss'

const SquareButton = ({ children, disabled, onClick }) => (
    <button type="submit" onClick={onClick} className={style.squareButton} disabled={disabled}>
        {children}
    </button>
)

SquareButton.propTypes = {
    // children: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
}

SquareButton.defaultProps = {
    disabled: false,
    onClick: null,
}

export default SquareButton
