import React, { Fragment } from 'react'
import SocialIcon from './components/SocialIcon'

const SocialIcons = () => (
    <Fragment>
        <SocialIcon
            brand
            name="facebook-f"
            className="facebook"
            title="Facebook"
            href="//www.facebook.com/eveonline/"
        />
        <SocialIcon
            brand
            name="discord"
            className="discord"
            title="Discord"
            href="//www.discord.com/projectnova/"
        />
        <SocialIcon
            brand
            name="twitter"
            className="twitter"
            title="Twitter"
            href="//www.twitter.com/eveonline"
        />
        <SocialIcon
            brand
            name="instagram"
            className="instagram"
            title="Instagram"
            href="//www.instagram.com/"
        />
        <SocialIcon
            brand
            name="youtube"
            className="youtube"
            title="Youtube"
            href="//www.youtube.com/channel/"
        />
    </Fragment>
)

export default SocialIcons
