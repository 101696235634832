import React from 'react'
import PropTypes from 'prop-types'
import { Switch } from 'react-router-dom'
import posed, { PoseGroup } from 'react-pose'

import Newsletter from 'containers/newsletter'
import Home from 'containers/home'
import Error from 'containers/error'
import AppliedRoute from './AppliedRoute'
import NotFound from '../containers/notfound'

const RoutesContainer = posed.div({
    enter: {
        y: 0,
        opacity: 1,
        delay: 100,
        beforeChildren: true,
    },
    exit: {
        y: 50,
        opacity: 0,
        transition: { duration: 200 },
    },
})

const RoutesBasic = ({ location, childProps }) => (
    <PoseGroup>
        <RoutesContainer key={location.pathname}>
            <Switch location={location}>
                <AppliedRoute exact template="home" path="/" component={Home} props={childProps} />
                <AppliedRoute exact path="/newsletter" component={Newsletter} props={childProps} />
                <AppliedRoute
                    exact
                    path="/error"
                    component={Error}
                    status={500}
                    props={childProps}
                />
                <AppliedRoute component={NotFound} status={404} props={childProps} />
            </Switch>
        </RoutesContainer>
    </PoseGroup>
)

RoutesBasic.propTypes = {
    childProps: PropTypes.shape({
        isAuthenticated: PropTypes.bool,
        userHasAuthenticated: PropTypes.func,
    }).isRequired,
    location: PropTypes.shape({
        key: PropTypes.string,
    }).isRequired,
}

export default RoutesBasic
