import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
// import { Nav, Navbar, NavItem } from 'react-bootstrap'
// import { Nav, Navbar, NavItem } from 'react-bootstrap'
import AppContext from 'context'
import config from 'config'
import SocialIcons from '../social-icons'
import style from './header.module.scss'
import { LoginButton, LogoutButton } from '../button'
import logo from '../../static/logo.png'

const Header = ({ logout }) => (
    <AppContext.Consumer>
        {context => (
            <header>
                <div className={style.container}>
                    <Link to="/" className={style.logo}>
                        <img className={style.logo_lg} src={logo} alt="" />
                    </Link>
                    {/* <nav> */}
                    {/* <Link to="/news">News</Link>
                        <Link to="/download">Download</Link> */}
                    {/* <Link to="/packs">Dummy packs</Link> */}
                    {/* <Link to="/store">Store</Link> */}
                    {/* </nav> */}

                    <div className={style.social}>
                        <SocialIcons />
                    </div>
                    {config.storeEnabled && (
                        <ul className={style.subNav}>
                            <li>
                                {context.isAuthenticated && context.user ? (
                                    <LogoutButton
                                        handleClick={logout}
                                        title={context.user.idToken.payload.email}
                                    >
                                        Logout
                                    </LogoutButton>
                                ) : (
                                    <LoginButton to="/login">Login</LoginButton>
                                )}
                            </li>
                        </ul>
                    )}
                </div>
            </header>
        )}
    </AppContext.Consumer>
)

Header.propTypes = {
    logout: PropTypes.func,
}

Header.defaultProps = {
    logout: null,
}

export default Header
