import { withFormik } from 'formik'
import * as Yup from 'yup'
import { NewsletterForm } from 'components/forms'

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email()
        .required('Please enter your email'),
    terms: Yup.boolean().oneOf([true], 'You have to agree to the terms'),
})

const Newsletter = withFormik({
    validationSchema,
    mapPropsToValues: () => ({
        email: '',
        terms: false,
    }),
    handleSubmit: (values, { props, setStatus, setSubmitting }) => {
        console.log('values', values)
        console.log('props', props)
        console.log('NEWSLETTER SUBMIT')

        setSubmitting(true)

        // console.log('submitForm', submitForm)
        // submitForm()

        // fetch('https://cl.exct.net/subscribe.aspx', {
        //     method: 'POST',
        //     body: JSON.stringify(userData),
        //     headers: {
        //         Accept: 'application/json',
        //         'Content-Type': 'application/json',
        //     },
        // }).then((response) => {
        //     response.json().then((data) => {
        //         console.log(`Successful${data}`)
        //     })
        // })
        setStatus({ success: true })

        console.log('NEWSLETTER SUBMIT ENDS')
        setSubmitting(false)
        // setErrors({ email: 'error man!' })

        // setSubmitting(false)
    },
    displayName: 'NewsletterForm', // helps with React DevTools
})(NewsletterForm)

export default Newsletter
