import React from 'react'
// import { Trapezoid } from 'components/button'
import Input from 'components/home/alpha-input'
import Video from 'components/video'
// import Icon from 'components/fontAwesome/FontAwesome'
import Visibility from 'components/common/visibility'
import Container from 'components/layout/container'
// import Products from 'components/products'
import Heading from 'components/home/heading'
import SEO from 'components/common/seo'
// import heroVideoMp4 from 'static/hero-video.mp4'
import heroVideoWebm from 'static/hero-video.webm'
import heroBg from 'static/hero.jpg'
import announcementPoster from 'static/video-bg.jpg'
import gameplayPoster from 'static/gameplay-trailer-bg.jpg'
import newsItem from 'static/news-item-1.jpg'
import style from './Home.module.scss'

const HomeBasic = () => (
    <div className={style.HomeContainer}>
        <SEO title="Home" />
        <section className={style.hero}>
            <div className={style.videoLoop}>
                <video playsInline autoPlay muted poster={heroBg} preload="auto">
                    <source src={`${process.env.PUBLIC_URL}/hero-video.mp4`} type="video/mp4" />
                    <source src={heroVideoWebm} type="video/webm" />
                </video>
            </div>
            <Container className={style.heroContainer}>
                <Visibility delay>
                    <h2>Lead the fight</h2>
                    <h3>In an explosive PVP & co-op shooter</h3>
                    <p>
                        Project Nova is a dark and thrilling first-person PvP & co-op shooter on PC,
                        set in the dystopian EVE universe and featuring strong emphasis on mastery
                        and strategy, co-evolved with the community.
                    </p>
                    <span className={style.date}>Play it first - Invite-only Alpha</span>
                    <Input />
                    {/* <Trapezoid to="/store">
                        Pre-order now <Icon brand name="windows" />
                    </Trapezoid> */}
                </Visibility>
            </Container>
        </section>
        <section className={style.video}>
            <Container>
                <Video
                    id="khobE3rUods"
                    caption="Project Nova announcement trailer"
                    poster={announcementPoster}
                />
            </Container>
        </section>
        <section className={style.news}>
            <Container className={style.newsWrapper}>
                <div className={style.item}>
                    <img className={style.img} src={newsItem} alt="" />
                    <div className={style.content}>
                        <h3>HOTFIX APPLIED TO SECRETS OF THE ABYSS EVENT!</h3>
                        <p>Aug 18th 2018 - By CCP Falcon</p>
                    </div>
                </div>
                <div className={style.item}>
                    <img className={style.img} src={newsItem} alt="" />
                    <div className={style.content}>
                        <h3>Join the forums</h3>
                        <p>Aug 18th 2018 - By CCP Falcon</p>
                    </div>
                </div>
                <div className={style.item}>
                    <img className={style.img} src={newsItem} alt="" />
                    <div className={style.content}>
                        <h3>HOTFIX APPLIED TO SECRETS OF THE ABYSS EVENT!</h3>
                        <p>Aug 18th 2018 - By CCP Falcon</p>
                    </div>
                </div>
            </Container>
        </section>
        <section className={style.about}>
            <Container>
                <div className={style.aboutIntro}>
                    <h3>About the Game</h3>
                    <Heading>Rise in the time of darkness</Heading>
                </div>
                <div className={style.content}>
                    <div className={style.text}>
                        <p>
                            Push back the invading hordes of Sansha's Nation as you are conscripted
                            by CONCORD in fierce co-op PvE action, or partake in breathless PvP
                            battles as a gun for hire while fighting other mercenaries to gain
                            riches and reputation. Empire warfare allows you to enter the theater of
                            war for one of New Eden's Empires in glorious competitive PvP.
                        </p>
                        <p>
                            Every reward brings you a step closer to your goals, allowing you to
                            upgrade your arsenal and gain access to powerful technology. Salvage
                            valuable materials and sell them for profit, gain loyalty by proving
                            yourself and get paid handsomely for your exploits on the battlefield.
                        </p>
                    </div>
                    <Video
                        className={style.video}
                        id="khobE3rUods"
                        caption="Project Nova gameplay trailer"
                        poster={gameplayPoster}
                    />
                </div>
            </Container>
        </section>
    </div>
)

export default HomeBasic
