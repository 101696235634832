import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import ErrorBoundary from 'containers/error/ErrorBoundary'
import Header from 'components/header/Header'
import Footer from 'components/footer/Footer'
import RoutesBasic from 'routes/RoutesBasic'
import 'normalize.css'
import AppContext from 'context'
import config from 'config'
import NotFound from 'containers/notfound'
import style from 'App.module.scss'
import 'components/fontAwesome/Lib'

class AppAuth extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isAuthenticated: false,
        }
    }

    render() {
        const { isAuthenticated } = this.state
        const { location } = this.props

        const childProps = {
            isAuthenticated,
            userHasAuthenticated: this.userHasAuthenticated,
            handleLogout: this.handleLogout,
        }

        return (
            <ErrorBoundary>
                <AppContext.Provider value={this.state}>
                    {config.splashEnabled ? (
                        <NotFound />
                    ) : (
                        <Fragment>
                            <Header />
                            <RoutesBasic location={location} childProps={childProps} />
                            <Footer />
                        </Fragment>
                    )}
                </AppContext.Provider>
            </ErrorBoundary>
        )
    }
}

export default withRouter(AppAuth)
