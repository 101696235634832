import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { SlideInFromLeft, SlideUp } from 'components/animations'
import PageHeading from 'components/common/pageHeading'
import Container from 'components/layout/container'
import style from './FormWrapper.module.scss'

const FormWrapper = ({ title, children, className }) => (
    <Container className={classnames(style.formWrapper, className)}>
        <div className={style.title}>
            <SlideInFromLeft>
                <PageHeading>{title}</PageHeading>
            </SlideInFromLeft>
        </div>

        <div className={style.form}>
            <SlideUp>
                <div className={classnames(style.inner, style[className])}>{children}</div>
            </SlideUp>
        </div>
    </Container>
)

FormWrapper.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
}

FormWrapper.defaultProps = {
    className: null,
}

export default FormWrapper
