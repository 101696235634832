import React, { Component } from 'react'
import PropTypes from 'prop-types'
import VisibilitySensor from 'react-visibility-sensor'
import classnames from 'classnames'
import './Visibility.module.scss'

class Visibility extends Component {
    state = {
        isVisible: false,
    }

    handleChange = (isVisible) => {
        if (isVisible) {
            this.setState({ isVisible: true })
        }
    }

    render() {
        const { children, direction, delay, className } = this.props
        const { isVisible } = this.state
        return (
            <VisibilitySensor onChange={this.handleChange} offset={{ top: 30 }} partialVisibility>
                <div
                    className={classnames(
                        'visibility',
                        direction,
                        delay && 'delay',
                        isVisible ? 'isVisible' : 'inVisible',
                        className,
                    )}
                >
                    {children}
                </div>
            </VisibilitySensor>
        )
    }
}

Visibility.propTypes = {
    children: PropTypes.node.isRequired,
    direction: PropTypes.string,
    delay: PropTypes.bool,
    className: PropTypes.string,
}

Visibility.defaultProps = {
    direction: 'fadeUp',
    delay: false,
    className: null,
}

export default Visibility
