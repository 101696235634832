import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
// import Amplify from '@aws-amplify/core'
import Raven from 'raven-js'
import ScrollToTop from './components/common/scrollToTop'
import './index.css'
import App from './App'
import config from './config'
import * as serviceWorker from './serviceWorker'

// Sentry Project: nova-web
Raven.config(config.sentry.dsn).install()

ReactDOM.render(
    <BrowserRouter>
        <ScrollToTop>
            <App />
        </ScrollToTop>
    </BrowserRouter>,
    document.getElementById('root'),
)

serviceWorker.unregister()
