import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Visibility from 'react-visibility-sensor'
import classnames from 'classnames'
import style from './Heading.module.scss'

class Heading extends Component {
    state = {
        isVisible: false,
    }

    onChange = (isVisible) => {
        if (isVisible) {
            this.setState({ isVisible: true })
        }
    }

    render() {
        const { isVisible } = this.state
        const { children, color } = this.props
        return (
            <Visibility onChange={this.onChange}>
                <h2
                    className={classnames(
                        style.heading,
                        style[color],
                        isVisible && style.active,
                    )}
                >
                    {children}
                </h2>
            </Visibility>
        )
    }
}

Heading.propTypes = {
    children: PropTypes.node.isRequired,
    color: PropTypes.string,
}

Heading.defaultProps = {
    color: 'primary',
}

export default Heading
