import React from 'react'
import { Input } from 'semantic-ui-react'
import style from './AlphaInput.module.scss'

const AlphaInput = () => (
    <div className={style.alphaInput}>
        <Input className={style.input} action="REGISTER" placeholder="Email" />

    </div>
)

export default AlphaInput
