// import React from 'react'
// import Loadable from 'react-loadable'
// import Loading from '../../components/loading'

// const Home = Loadable({
//     loader: () => import('./Home' /* webpackChunkName: 'Home' */),
//     loading() {
//         return <Loading />
//     },
// })

// export default Home
export { default } from './HomeBasic'
