import React from 'react'

const whitespace = ' \t\n\r'

// Check whether string s is empty.
function isEmpty(s) {
    return s == null || s.length == 0
}

// Returns true if string s is empty or
// whitespace characters only.
function isWhitespace(s) {
    let i

    // Is s empty?
    if (isEmpty(s)) return true

    // Search through string's characters one by one
    // until we find a non-whitespace character.
    // When we do, return false; if we don't, return true.
    for (i = 0; i < s.length; i++) {
        // Check that current character isn't whitespace.
        const c = s.charAt(i)

        if (whitespace.indexOf(c) == -1) return false
    }
    // All characters are whitespace.
    return true
}
//
// Email address must be of form a@b.c ... in other words:
// * there must be at least one character before the at sign
// * there must be at least one character before and after the .
function isValidEmail(s) {
    console.log('test')
    if (isEmpty(s)) return false

    // is s whitespace?
    if (isWhitespace(s)) return false

    // there must be >= 1 character before at sign, so we
    // start looking at character position 1
    // (i.e. second character)
    let i = 1
    const sLength = s.length

    // look for at sign
    while (i < sLength && s.charAt(i) != '@@') {
        i++
    }

    if (i >= sLength || s.charAt(i) != '@@') return false
    i += 2

    // look for .
    while (i < sLength && s.charAt(i) != '.') {
        i++
    }

    // there must be at least one character after the .
    if (i >= sLength - 1 || s.charAt(i) != '.') return false
    return true
}

function showWarningMessage(message) {
    document.getElementById('Message').style.display = 'block'
    document.getElementById('Message').innerHTML = message
    // Hide error div if it is on the page
    if (document.getElementById('Error') !== null) {
        document.getElementById('Error').style.display = 'none'
    }
}

function checkForm() {
    console.log('checkForm')
    let z = 0
    const celements = document.getElementsByName('lid')
    for (let c = 0; c < celements.length; c++) {
        if (celements[c].checked) {
            z += 1
        }
    }
    if (z < 1) {
        showWarningMessage('You have to agree to the terms')
        return false
    }
    if (!isValidEmail(document.subscribeForm.elements['Email Address'].value)) {
        showWarningMessage('Please enter a valid email address')
        document.subscribeForm.elements['Email Address'].focus()
        return false
    }

    // Disable button before submitting.
    document.subscribeForm.elements.submitButton.disabled = true
    document.getElementById('posting').style.display = 'block'
    // Adding spinner to show that something is going on.
    return false
}

export default () => (
    <form
        // action="https://cl.exct.net/subscribe.aspx"
        // name="subscribeForm"
        // method="post"
        onSubmit={checkForm}
    >
        <input type="hidden" name="thx" value="https://www.ccpgames.com/projectnova?thankyou" />
        <input type="hidden" name="err" value="https://www.ccpgames.com/projectnova" />
        <input type="hidden" name="SubAction" value="sub" />
        <input type="hidden" name="MID" value="7231600" />

        <div id="Message" className="alert alert-warning" role="alert" />

        <div className="group">
            <input type="text" name="Email Address" placeholder="" required />
            <span className="highlight" />
            <span className="bar" />
            <label>Sign up and receive up-to-date news and announcements for Project Nova</label>
        </div>

        <div className="form-group">
            <input
                className="form-check-input"
                type="checkbox"
                id="confirmation"
                name="lid"
                value="19405"
            />
            <label className="form-check-label" htmlFor="confirmation">
                I agree to allow CCP Games to send me product news and promotional communications.
            </label>
        </div>
        <div className="form-group">
            <input className="btn btn-nova" name="submitButton" type="submit" value="Submit" />
        </div>
    </form>
)
