import React from 'react'
import Loadable from 'react-loadable'
import Loading from 'components/loading'

const AppAuth = Loadable({
    loader: () => import('./AppAuth' /* webpackChunkName: 'AppAuth' */),
    loading() {
        return <Loading />
    },
})

export default AppAuth
// export { default } from './AppAuth'
