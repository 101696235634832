import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { AppAuth, AppBasic } from 'containers/app'
import config from 'config'
import 'normalize.css'

import style from './App.module.scss'
import './components/fontAwesome/Lib'

class App extends Component {
    async componentDidMount() {
        this.LoadingDelay().then(() => {
            const ele = document.getElementById('ipl-progress-indicator')
            if (ele) {
                // fade out
                ele.classList.add('available')
                setTimeout(() => {
                    // remove from DOM
                    ele.outerHTML = ''
                }, 2000)
            }
        })
    }

    // fake authentication Promise
    LoadingDelay = () => new Promise(resolve => setTimeout(resolve, 250))

    render() {
        return <Fragment>{config.storeEnabled ? <AppAuth /> : <AppBasic />}</Fragment>
    }
}

export default withRouter(App)
